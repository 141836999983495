<template>
  <div>
    <shipblu-prompt
      class="shipment-modal"
      @close="closeModal"
      :active.sync="holidayModal"
      :title="$t('Add Holiday')"
      :buttons-hidden="true">
        <div class="vx-row mb-2">              
          <template>
            <div class="vx-col w-1/2">
              <datepicker :inline="true" v-validate="'required'" name="date" :placeholder="$t('Date') + '*'" v-model="holiday.date" class="mt-8 w-full"></datepicker>
              <span class="text-danger text-sm" v-show="errors.has('date')">{{ errors.first('date') }}</span>
            </div>
            <div class="vx-col w-1/2">
              <v-select multiple autocomplete="nofill" class="select-large mt-8 w-full" name="applies to" v-model="holiday.applies_to" 
              v-validate="'required'" :placeholder="$t('Applies To') + '*'" :options="types"/>
              <span class="text-danger text-sm" v-show="errors.has('applies to')">{{ errors.first('applies to') }}</span>
            </div>
          </template>
        </div>
        <div class="grid grid-cols-2 gap-4 mt-4">
          <button @click="closeModal" class="btn disable-btn">{{ $t('Cancel') }}</button>
          <button @click="addHoliday" class="active-btn btn">{{ $t('Add') }}</button>
        </div>
    </shipblu-prompt>
  </div>
</template>

<script>
import ShipbluPrompt from '../../../layouts/components/ShipBluPrompt.vue'
import {sendRequest} from '../../../http/axios/requestHelper.js'
import i18nData from '../../../i18n/i18nData.js'
import Datepicker from 'vuejs-datepicker'
import vSelect from 'vue-select'
import common from '../../../assets/utils/common'

export default {
  props: ['holidayModal', 'holiday'],
  data () {
    return {
      types: ['first_mile', 'middle_mile', 'last_mile', 'finance']
    }
  },
  watch: {
    'holiday.date' (val) {
      this.holiday.date = common.formatDate(val)
    }
  },
  methods: {
    addHoliday () {
      this.$validator.validateAll().then(result => {
        if (result) {
          if (!this.isOnline) this.$emit('holidayModal', false)
          sendRequest(false, false, this, 'api/v1/task-sheets/holidays/', 'post', this.holiday, true,
            () => {
              this.$vs.notify({
                color:'success',
                title:i18nData[this.$i18n.locale]['Success'],
                text:i18nData[this.$i18n.locale]['Holiday'] + i18nData[this.$i18n.locale][' has been created successfully!'],
                position: 'top-center'
              })
              this.closeModal()
            }
          )
        }
      })
    },
    closeModal () {
      this.$emit('loadHolidays')
      this.$emit('holidayModal', false)
    }
  },
  components: {
    ShipbluPrompt,
    Datepicker,
    vSelect
  }
}
</script>