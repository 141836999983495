<template>
  <div id="merchant-page">
    <div class="vx-row">
      <div class="vx-col w-full lg:w-1/2 mb-base">
        <h2>{{$t('Holidays')}}</h2>
      </div>
      <div class="vx-col w-full lg:w-1/2 items-end">
        <div class="flex justify-between">
          <span class="font-semibold"></span>
          <span class="font-medium text-primary cursor-pointer">
            <vs-button @click="openHolidayModal('add')" color="primary" icon-pack="feather" icon="icon-plus" size="large">
            </vs-button>
          </span>
        </div>
      </div>
    </div>

    <shipblu-table
      :sst="true"
      multiple
      v-model="selected"
      pagination
      :max-items="maximumItems"
      :data="holidays"
      :tableLoader="tableLoader"
    >
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between"></div>
      <template slot="thead">
        <shipblu-th>{{$t('Date')}}</shipblu-th>
        <shipblu-th>{{$t('Applies To')}}</shipblu-th>
        <shipblu-th>{{$t('Actions')}}</shipblu-th>
      </template>

      <template slot-scope="{ data }">
        <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <shipblu-td :data="data[indextr].date">
            {{ data[indextr].date }}
          </shipblu-td>

          <shipblu-td :data="data[indextr].apllies">
            <p v-for="item in data[indextr].applies_to" :key="item.index">{{ item }}</p>
          </shipblu-td>

          <shipblu-td>
            <vs-dropdown
              vs-trigger-click
              @click="selected = []"
              class="dd-actions cursor-pointer"
            >
              <vs-button
                type="border"
                size="small"
                icon-pack="feather"
                icon="icon-more-horizontal"
                class="mr-2"
              >
              </vs-button>
              <vs-dropdown-menu style="width: 190px">
                <vs-dropdown-item  @click="deleteHoliday(data[indextr])">
                  <span class="flex items-center">
                    <feather-icon
                      icon="TrashIcon"
                      svgClasses="h-4 w-4"
                      class="mr-2"
                    />
                    <span>{{$t('Delete')}}</span>
                  </span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </shipblu-td>
        </shipblu-tr>
      </template>
    </shipblu-table>
    <shipblu-pagination :maximumItems="maximumItems" :noOptions="true" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" />
    <add-holiday :holiday="holiday" :holidayModal="holidayModal" @holidayModal="holidayModal = $event" @loadHolidays="loadHolidays"></add-holiday>
  </div>
</template>

<script>
import { sendRequest }  from '../../http/axios/requestHelper.js'
import ShipbluTable from '../../layouts/components/ShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import AddHoliday from './components/AddHoliday.vue'
import i18nData from '../../i18n/i18nData.js'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'

export default {
  data () {
    return {
      selected: [],
      maximumItems: process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      holidays: [],
      holidayModal: false,
      offset: 0,
      totalRows: 0,
      tableLoader: false,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      deleteData: {},
      holiday: {}
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadHolidays()
    }
  },
  methods: {
    loadHolidays () {
      this.tableLoader = true
      const query = `?offset=${this.offset}&limit=${this.maximumItems}`
      sendRequest(true, false, this, `api/v1/task-sheets/holidays/${query}`, 'get', null, true,
        (response) => {
          this.holidays = response.data.results
          this.totalRows = response.data.count
          this.tableLoader = false
        }
      )
    },
    openHolidayModal () {
      this.holidayModal = true
      this.holiday = {
        date: ''
      }
    },
    deleteHoliday (data) {
      this.deleteData = data
      this.$vs.dialog({
        color: 'danger',
        title: 'Confirm',
        text: i18nData[this.$i18n.locale]['Are you sure to delete?'],
        accept: this.confirmDelete
      })
    },
    confirmDelete () {
      sendRequest(false, false, this, `api/v1/task-sheets/holidays/${this.deleteData.id}/`, 'delete', null, true,
        () => {
          this.loadHolidays()
        }
      )
    }
  },
  created () {
    this.offset = (this.currentPage - 1) * this.maximumItems
    this.loadHolidays()
  },
  components: {
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    AddHoliday,
    ShipbluPagination
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
.con-pagination-table{
  display: none;
}
</style>
